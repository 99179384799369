import "core-js/modules/es.array.push.js";
import { getUserPage, importTeacher, resetPassword } from "@/api/api";
export default {
  name: "teacherList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        type: 3,
        name: '',
        user_no: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getUserPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$root.useRouter.push({
        path: "/user/userEdit",
        query: {
          Info: data,
          type: 3
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    resetPassword(id) {
      resetPassword({
        id: id
      }).then(res => {
        this.$root.msgResut(res);
      });
    },
    beforeAvatarUpload(e) {
      let formData = new FormData();
      formData.append('file', e);
      importTeacher(formData).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.search();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    downFile(name) {
      let a = document.createElement("a"); //创建一个<a></a>标签
      a.href = "/template/" + name + ".xlsx"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = "教师导入模板.xlsx"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    }
  }
};